import React from 'react';

import Body from '../../templates/Body/Body';

import './PrivacyPolicy.css';

export default function PrivacyPolicy() {
    return (
        <Body bodyClass="content" title="Privacy Policy | CinEd">
            <div className="container pageContainer">
                <h1 className="contactsTitle h1Text">
                    <b>Privacy Policy</b>
                </h1>
                <p className="last-update">Last updated October 25, 2023</p>

                <p className="resourcesText normalText">
                    This privacy notice for the CinEd Website ("we," "us," or "our"), describes how and why we might
                    collect, store, use, and/or share ("process") your information when you use our services
                    ("Services"), such as when you visit our website at{' '}
                    <a href="https://cined.eu/" target="_blank" rel="noreferrer">
                        https://cined.eu/
                    </a>
                    . This website was developed by MOG Technologies as part of the CinEd Project.
                </p>

                <p className="resourcesText normalText">
                    <b>Questions or concerns?</b> Reading this privacy notice will help you understand your privacy
                    rights and choices. If you do not agree with our policies and practices, please do not use our
                    Services.
                </p>

                <h3 className="resourcesH3 h3Text">SUMMARY OF KEY POINTS</h3>

                <p className="resourcesText highlight normalText">
                    This summary provides key points from our privacy notice, but you can find out more details about
                    any of these topics by clicking the link following each key point or by using our{' '}
                    <a href="#toc">table of contents</a> below to find the section you are looking for.
                </p>

                <p className="resourcesText normalText">
                    <b>What personal information do we process?</b> When you visit, use, or navigate our Services, we
                    may process personal information depending on how you interact with us and the Services, the choices
                    you make, and the products and features you use. Learn more about{' '}
                    <a href="#personalinfo">personal information you disclose to us</a>.
                </p>

                <p className="resourcesText normalText">
                    <b>Do we process any sensitive personal information?</b> We do not process sensitive personal
                    information.
                </p>

                <p className="resourcesText normalText">
                    <b>Do we receive any information from third parties?</b> We do not receive any information from
                    third parties.
                </p>

                <p className="resourcesText normalText">
                    <b>How do we process your information?</b> We process your information to provide, improve, and
                    administer our Services, communicate with you, for security and fraud prevention, and to comply with
                    law. We may also process your information for other purposes with your consent. We process your
                    information only when we have a valid legal reason to do so. Learn more about{' '}
                    <a href="#infouse">how we process your information</a>.
                </p>

                <p className="resourcesText normalText">
                    <b>In what situations and with which types of parties do we share personal information?</b> We may
                    share information in specific situations and with specific categories of third parties. Learn more
                    about <a href="#whoshare">when and with whom we share your personal information</a>.
                </p>

                <p className="resourcesText normalText">
                    <b>How do we keep your information safe?</b> We have organizational and technical processes and
                    procedures in place to protect your personal information. However, no electronic transmission over
                    the internet or information storage technology can be guaranteed to be 100% secure, so we cannot
                    promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be
                    able to defeat our security and improperly collect, access, steal, or modify your information. Learn
                    more about <a href="#infosafe">how we keep your information safe</a>.
                </p>

                <p className="resourcesText normalText">
                    <b>What are your rights?</b> Depending on where you are located geographically, the applicable
                    privacy law may mean you have certain rights regarding your personal information. Learn more about{' '}
                    <a href="#privacyrights">your privacy rights</a>.
                </p>

                <p className="resourcesText normalText">
                    <b>How do you exercise your rights?</b> The easiest way to exercise your rights is by submitting a{' '}
                    <a
                        href="https://app.termly.io/notify/a7abcbae-5250-40f6-bc9e-67ddd722ac88"
                        target="_blank"
                        rel="noreferrer"
                    >
                        data subject access request
                    </a>
                    , or by contacting us. We will consider and act upon any request in accordance with applicable data
                    protection laws.
                </p>

                <p className="resourcesText normalText">
                    <b>Want to learn more about what we do with any information we collect?</b>{' '}
                    <a href="#toc">Review the privacy notice in full</a>.
                </p>

                <h3 id="toc" className="resourcesH3 h3Text">
                    TABLE OF CONTENTS
                </h3>

                <ol className="resourcesText normalText">
                    <li>
                        <a href="#infocollect">WHAT INFORMATION DO WE COLLECT?</a>
                    </li>
                    <li>
                        <a href="#infouse">HOW DO WE PROCESS YOUR INFORMATION?</a>
                    </li>
                    <li>
                        <a href="#legalbases">WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION?</a>
                    </li>
                    <li>
                        <a href="#whoshare">WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</a>
                    </li>
                    <li>
                        <a href="#cookies">DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</a>
                    </li>
                    <li>
                        <a href="#inforetain">HOW LONG DO WE KEEP YOUR INFORMATION?</a>
                    </li>
                    <li>
                        <a href="#infosafe">HOW DO WE KEEP YOUR INFORMATION SAFE?</a>
                    </li>
                    <li>
                        <a href="#privacyrights">WHAT ARE YOUR PRIVACY RIGHTS?</a>
                    </li>
                    <li>
                        <a href="#DNT">CONTROLS FOR DO-NOT-TRACK FEATURES</a>
                    </li>
                    <li>
                        <a href="#policyupdates">DO WE MAKE UPDATES TO THIS NOTICE?</a>
                    </li>
                    <li>
                        <a href="#contact">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>
                    </li>
                    <li>
                        <a href="#request">HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</a>
                    </li>
                </ol>

                <h3 id="infocollect" className="resourcesH3 h3Text">
                    1. WHAT INFORMATION DO WE COLLECT?
                </h3>

                <h4 id="personalinfo" className="privacySubtitle h4Text">
                    Personal information you disclose to us
                </h4>

                <p className="resourcesText italic normalText">
                    <b>In Short:</b> We collect personal information that you provide to us.
                </p>

                <p className="resourcesText normalText">
                    We collect personal information that you voluntarily provide to us when you register on the
                    Services, express an interest in obtaining information about us or our products and Services, when
                    you participate in activities on the Services, or otherwise when you contact us.
                </p>

                <p className="resourcesText normalText">
                    <b>Personal Information Provided by You.</b> The personal information that we collect depends on the
                    context of your interactions with us and the Services, the choices you make, and the products and
                    features you use. The personal information we collect may include the following:
                </p>

                <ul className="resourcesText normalText">
                    <li>names</li>
                    <li>phone numbers</li>
                    <li>email addresses</li>
                    <li>job titles</li>
                    <li>usernames</li>
                    <li>passwords</li>
                    <li>birth date</li>
                    <li>country</li>
                    <li>region</li>
                </ul>

                <p className="resourcesText normalText">
                    <b>Sensitive Information.</b> We do not process sensitive information.
                </p>

                <p className="resourcesText normalText">
                    All personal information that you provide to us must be true, complete, and accurate, and you must
                    notify us of any changes to such personal information.
                </p>

                <h4 className="privacySubtitle h4Text">Information automatically collected</h4>

                <p className="resourcesText italic normalText">
                    <b>In Short:</b> Some information — such as your Internet Protocol (IP) address and/or browser and
                    device characteristics — is collected automatically when you visit our Services.
                </p>

                <p className="resourcesText normalText">
                    We automatically collect certain information when you visit, use, or navigate the Services. This
                    information does not reveal your specific identity (like your name or contact information) but may
                    include device and usage information, such as your IP address, browser and device characteristics,
                    operating system, language preferences, referring URLs, device name, country, information about how
                    and when you use our Services, and other technical information. This information is primarily needed
                    to maintain the security and operation of our Services, and for our internal analytics and reporting
                    purposes.
                </p>

                <p className="resourcesText normalText">
                    Like many other websites, we also collect information through cookies and similar technologies.
                </p>

                <p className="resourcesText normalText">The information we collect includes:</p>

                <ul className="resourcesText normalText">
                    <li>
                        <i>Log and Usage Data.</i> Log and usage data is service-related, diagnostic, usage, and
                        performance information our servers automatically collect when you access or use our Services
                        and which we record in log files. Depending on how you interact with us, this log data may
                        include your IP address, device information, browser type, and settings and information about
                        your activity in the Services (such as the date/time stamps associated with your usage, pages
                        and files viewed, searches, and other actions you take such as which features you use), device
                        event information (such as system activity, error reports (sometimes called "crash dumps"), and
                        hardware settings).
                    </li>
                    <li>
                        <i>Device Data.</i> We collect device data such as information about your computer, phone,
                        tablet, or other device you use to access the Services. Depending on the device used, this
                        device data may include information such as your IP address (or proxy server), device and
                        application identification numbers, browser type, hardware model, Internet service provider
                        and/or mobile carrier, operating system, and system configuration information.
                    </li>
                </ul>

                <h3 id="infouse" className="resourcesH3 h3Text">
                    2. HOW DO WE PROCESS YOUR INFORMATION?
                </h3>

                <p className="resourcesText italic normalText">
                    <b>In Short:</b> We process your information to provide, improve, and administer our Services,
                    communicate with you, for security and fraud prevention, and to comply with law. We may also process
                    your information for other purposes with your consent.
                </p>

                <p className="resourcesText highlight normalText">
                    We process your personal information for a variety of reasons, depending on how you interact with
                    our Services, including:
                </p>

                <ul className="resourcesText normalText">
                    <li>
                        <b>To facilitate account creation and authentication and otherwise manage user accounts.</b> We
                        may process your information so you can create and log in to your account, as well as keep your
                        account in working order.
                    </li>
                    <li>
                        <b>To deliver and facilitate delivery of services to the user.</b> We may process your
                        information to provide you with the requested service.
                    </li>
                    <li>
                        <b>To save or protect an individual's vital interest.</b> We may process your information when
                        necessary to save or protect an individual’s vital interest, such as to prevent harm.
                    </li>
                </ul>

                <h3 id="legalbases" className="resourcesH3 h3Text">
                    3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?
                </h3>

                <p className="resourcesText italic normalText">
                    <b>In Short:</b> We only process your personal information when we believe it is necessary and we
                    have a valid legal reason (i.e., legal basis) to do so under applicable law, like with your consent,
                    to comply with laws, to provide you with services to enter into or fulfill our contractual
                    obligations, to protect your rights, or to fulfill our legitimate business interests.
                </p>

                <p className="resourcesText normalText">
                    The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal
                    bases we rely on in order to process your personal information. As such, we may rely on the
                    following legal bases to process your personal information:
                </p>

                <ul className="resourcesText normalText">
                    <li>
                        <b>Consent.</b> We may process your information if you have given us permission (i.e., consent)
                        to use your personal information for a specific purpose. You can withdraw your consent at any
                        time. Learn more about <a href="#withdrawconsent">withdrawing your consent</a>.
                    </li>
                    <li>
                        <b>Performance of a Contract.</b> We may process your personal information when we believe it is
                        necessary to fulfill our contractual obligations to you, including providing our Services or at
                        your request prior to entering into a contract with you.
                    </li>
                    <li>
                        <b>Legal Obligations.</b> We may process your information where we believe it is necessary for
                        compliance with our legal obligations, such as to cooperate with a law enforcement body or
                        regulatory agency, exercise or defend our legal rights, or disclose your information as evidence
                        in litigation in which we are involved.
                    </li>
                    <li>
                        <b>Vital Interests.</b> We may process your information where we believe it is necessary to
                        protect your vital interests or the vital interests of a third party, such as situations
                        involving potential threats to the safety of any person.
                    </li>
                </ul>

                <h3 id="whoshare" className="resourcesH3 h3Text">
                    4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                </h3>

                <p className="resourcesText italic normalText">
                    <b>In Short:</b> We may share information in specific situations described in this section and/or
                    with the following categories of third parties.
                </p>

                <p className="resourcesText normalText">
                    <b>Vendors, Consultants, and Other Third-Party Service Providers.</b> We may share your data with
                    third-party vendors, service providers, contractors, or agents ("<b>third parties</b>") who perform
                    services for us or on our behalf and require access to such information to do that work. We have
                    contracts in place with our third parties, which are designed to help safeguard your personal
                    information. This means that they cannot do anything with your personal information unless we have
                    instructed them to do it. They will also not share your personal information with any organization
                    apart from us. They also commit to protect the data they hold on our behalf and to retain it for the
                    period we instruct. The categories of third parties we may share personal information with are as
                    follows:
                </p>

                <ul className="resourcesText normalText">
                    <li>Data Analytics Services</li>
                </ul>

                <p className="resourcesText normalText">
                    We also may need to share your personal information in the following situations:
                </p>

                <ul className="resourcesText normalText">
                    <li>
                        <b>Business Transfers.</b> We may share or transfer your information in connection with, or
                        during negotiations of, any merger, sale of company assets, financing, or acquisition of all or
                        a portion of our business to another company.
                    </li>
                    <li>
                        <b>Business Partners.</b> We may share your information with our business partners to offer you
                        certain products, services, or promotions.
                    </li>
                </ul>

                <h3 id="cookies" className="resourcesH3 h3Text">
                    5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                </h3>

                <p className="resourcesText italic normalText">
                    <b>In Short:</b> We may use cookies and other tracking technologies to collect and store your
                    information.
                </p>

                <p className="resourcesText normalText">
                    We may use cookies and similar tracking technologies (like web beacons and pixels) to access or
                    store information, which are necessary for the website to function as intended.
                </p>

                <h3 id="inforetain" className="resourcesH3 h3Text">
                    6. HOW LONG DO WE KEEP YOUR INFORMATION?
                </h3>

                <p className="resourcesText italic normalText">
                    <b>In Short:</b> We keep your information for as long as necessary to fulfill the purposes outlined
                    in this privacy notice unless otherwise required by law.
                </p>

                <p className="resourcesText normalText">
                    We will only keep your personal information for as long as it is necessary for the purposes set out
                    in this privacy notice, unless a longer retention period is required or permitted by law (such as
                    tax, accounting, or other legal requirements). No purpose in this notice will require us keeping
                    your personal information for longer than the period of time in which users have an account with us.
                </p>

                <p className="resourcesText normalText">
                    When we have no ongoing legitimate business need to process your personal information, we will
                    either delete or anonymize such information, or, if this is not possible (for example, because your
                    personal information has been stored in backup archives), then we will securely store your personal
                    information and isolate it from any further processing until deletion is possible.
                </p>

                <h3 id="infosafe" className="resourcesH3 h3Text">
                    7. HOW DO WE KEEP YOUR INFORMATION SAFE?
                </h3>

                <p className="resourcesText italic normalText">
                    <b>In Short:</b> We aim to protect your personal information through a system of organizational and
                    technical security measures.
                </p>

                <p className="resourcesText normalText">
                    We have implemented appropriate and reasonable technical and organizational security measures
                    designed to protect the security of any personal information we process. However, despite our
                    safeguards and efforts to secure your information, no electronic transmission over the Internet or
                    information storage technology can be guaranteed to be 100% secure, so we cannot promise or
                    guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to
                    defeat our security and improperly collect, access, steal, or modify your information. Although we
                    will do our best to protect your personal information, transmission of personal information to and
                    from our Services is at your own risk. You should only access the Services within a secure
                    environment.
                </p>

                <h3 id="privacyrights" className="resourcesH3 h3Text">
                    8. WHAT ARE YOUR PRIVACY RIGHTS?
                </h3>

                <p className="resourcesText italic normalText">
                    <b>In Short:</b> In some regions, such as the European Economic Area (EEA), United Kingdom (UK), and
                    Switzerland, you have rights that allow you greater access to and control over your personal
                    information. You may review, change, or terminate your account at any time.
                </p>

                <p className="resourcesText normalText">
                    In some regions (like the EEA, UK, and Switzerland), you have certain rights under applicable data
                    protection laws. These may include the right (i) to request access and obtain a copy of your
                    personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of
                    your personal information; (iv) if applicable, to data portability; and (v) not to be subject to
                    automated decision-making. In certain circumstances, you may also have the right to object to the
                    processing of your personal information. You can make such a request by contacting us by using the
                    contact details provided in the section "
                    <a href="#contact">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>" below.
                </p>

                <p className="resourcesText normalText">
                    We will consider and act upon any request in accordance with applicable data protection laws.
                </p>

                <p className="resourcesText normalText">
                    If you are located in the EEA or UK and you believe we are unlawfully processing your personal
                    information, you also have the right to complain to your{' '}
                    <a
                        href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Member State data protection authority
                    </a>{' '}
                    or{' '}
                    <a
                        href="https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        UK data protection authority
                    </a>
                    .
                </p>

                <p className="resourcesText normalText">
                    If you are located in Switzerland, you may contact the{' '}
                    <a href="https://www.edoeb.admin.ch/edoeb/en/home.html" target="_blank" rel="noreferrer">
                        Federal Data Protection and Information Commissioner
                    </a>
                    .
                </p>

                <p id="withdrawconsent" className="resourcesText normalText">
                    <u>Withdrawing your consent</u>: If we are relying on your consent to process your personal
                    information, you have the right to withdraw your consent at any time. You can withdraw your consent
                    at any time by contacting us by using the contact details provided in the section "
                    <a href="#contact">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>" below.
                </p>

                <p className="resourcesText normalText">
                    However, please note that this will not affect the lawfulness of the processing before its
                    withdrawal nor, will it affect the processing of your personal information conducted in reliance on
                    lawful processing grounds other than consent.
                </p>

                <h4 className="privacySubtitle h4Text">Account Information</h4>

                <p className="resourcesText normalText">
                    If you would at any time like to review or change the information in your account or terminate your
                    account, you can:
                </p>

                <ul className="resourcesText normalText">
                    <li>Log in to your account settings and update your user account.</li>
                </ul>

                <p className="resourcesText normalText">
                    Upon your request to terminate your account, we will deactivate or delete your account and
                    information from our active databases. However, we may retain some information in our files to
                    prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or
                    comply with applicable legal requirements.
                </p>

                <p className="resourcesText normalText">
                    <u>Cookies and similar technologies:</u> Most Web browsers are set to accept cookies by default. If
                    you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If
                    you choose to remove cookies or reject cookies, this could affect certain features or services of
                    our Services.
                </p>

                <h3 id="DNT" className="resourcesH3 h3Text">
                    9. CONTROLS FOR DO-NOT-TRACK FEATURES
                </h3>

                <p className="resourcesText normalText">
                    Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track
                    ("DNT") feature or setting you can activate to signal your privacy preference not to have data about
                    your online browsing activities monitored and collected. At this stage no uniform technology
                    standard for recognizing and implementing DNT signals has been finalized. As such, we do not
                    currently respond to DNT browser signals or any other mechanism that automatically communicates your
                    choice not to be tracked online. If a standard for online tracking is adopted that we must follow in
                    the future, we will inform you about that practice in a revised version of this privacy notice.
                </p>

                <h3 id="policyupdates" className="resourcesH3 h3Text">
                    10. DO WE MAKE UPDATES TO THIS NOTICE?
                </h3>

                <p className="resourcesText italic normalText">
                    <b>In Short:</b> Yes, we will update this notice as necessary to stay compliant with relevant laws.
                </p>

                <p className="resourcesText normalText">
                    We may update this privacy notice from time to time. The updated version will be indicated by an
                    updated "Revised" date and the updated version will be effective as soon as it is accessible. If we
                    make material changes to this privacy notice, we may notify you either by prominently posting a
                    notice of such changes or by directly sending you a notification. We encourage you to review this
                    privacy notice frequently to be informed of how we are protecting your information.
                </p>

                <h3 id="contact" className="resourcesH3 h3Text">
                    11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                </h3>

                <p className="resourcesText normalText">
                    If you have questions or comments about this notice, you may contact us by post at:
                </p>

                <p className="resourcesText normalText">
                    MOG Technologies
                    <br />
                    R. Eng. Frederico Ulrich 3110
                    <br />
                    Maia 4470-605
                    <br />
                    Portugal
                    <br />
                </p>

                <h3 id="request" className="resourcesH3 h3Text">
                    12. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
                </h3>

                <p className="resourcesText normalText">
                    Based on the applicable laws of your country, you may have the right to request access to the
                    personal information we collect from you, change that information, or delete it. To request to
                    review, update, or delete your personal information, please fill out and submit a{' '}
                    <a
                        href="https://app.termly.io/notify/a7abcbae-5250-40f6-bc9e-67ddd722ac88"
                        target="_blank"
                        rel="noreferrer"
                    >
                        data subject access request
                    </a>
                    .
                </p>

                <p className="resourcesText normalText">
                    This privacy policy was created using Termly's{' '}
                    <a href="https://termly.io/products/privacy-policy-generator/" target="_blank" rel="noreferrer">
                        Privacy Policy Generator
                    </a>
                    .
                </p>
            </div>
        </Body>
    );
}
