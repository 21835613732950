import React from 'react';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';

import AccessPopup from '../../components/Popup/AccessPopup';
import Body from '../../templates/Body/Body';
import Loading from '../../components/Loading/Loading';
import VideoCard from '../../components/VideoCard/VideoCard';
import PageHeader from '../../components/PageHeader/PageHeader';
import PedagogicalVideos from '../PedagogicalVideos/PedagogicalVideos';
import UpdatePopup from '../../components/Popup/UpdatePopup';
import WelcomePopup from '../../components/Popup/WelcomePopup';
import { BASE_URL } from '../../utils/globals';
import { getMovieImageURL, getPlayer, getTranslation } from '../../utils/utils';
import { getMovies } from '../../api/movies';

import './Collection.css';

const Prefix = 'translations.';
const DefaultTranslation = Prefix + 'english';

class CollectionClass extends React.Component {
    constructor(props) {
        super(props);
        this.thematicDiv = React.createRef();
        this.state = {
            loading: true,
            movies: [],
            headerMovie: null,
            offset: false,
            openPlayerPopup: false,
            openUpdatedPopup: false,
            openWarning: false,
            user: null,
        };
    }

    async componentDidMount() {
        window.addEventListener('unload', this.unload, false);
        const userJSON = localStorage.getItem('user');
        const user = userJSON ? JSON.parse(userJSON) : null;
        const isTeacher = user?.userRole.toLowerCase() !== 'student';

        this.setState({ user: user });

        if (user?.customerId) {
            const welcome = localStorage.getItem('welcomeModal');
            const updatedPlayer = localStorage.getItem('updatedPlayerModal');
            const { support } = getPlayer();

            if (isTeacher) {
                if (!welcome) {
                    this.setState({ openPlayerPopup: true });
                } else if (support && welcome !== '2024' && !updatedPlayer) {
                    this.setState({ openUpdatedPopup: true });
                }
            }
        } else if (user?.migrationToken && isTeacher) {
            this.setState({ openWarning: true });
        }

        let moviesList;
        try {
            moviesList = await getMovies();
        } catch (error) {
            console.error('An error occurred: ', error);
            return;
        }

        this.setState({
            loading: false,
            movies: moviesList,
            headerMovie: this.getRandomMovie(moviesList),
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState !== this.state && !this.state.loading) {
            this.offsetSection();
        }

        if (this.props.t !== prevProps.t) {
            this.setState({ headerMovie: this.getRandomMovie(this.state.movies) });
        }
    }

    componentWillUnmount() {
        this.unload();
        window.removeEventListener('unload', this.unload, false);
    }

    offsetSection() {
        if (!this.state.offset) {
            const movieHeight = 0;
            const thematicHeight = this.thematicDiv.current?.getBoundingClientRect().top;
            const label = localStorage.getItem('label');

            if (label === 'Movies' || label === 'ThematicVideos') {
                window.scrollTo({
                    top: label === 'Movies' ? movieHeight : thematicHeight,
                    behavior: 'smooth',
                });
            }

            localStorage.removeItem('label');
            this.setState({ offset: true });
        }
    }

    getRandomMovie(moviesList) {
        const randomIdx = Math.floor(Math.random() * moviesList.length);
        const movie = moviesList[randomIdx];
        return movie;
    }

    unload() {
        localStorage.removeItem('label');
    }

    closeWelcomeModal() {
        this.setState({ openPlayerPopup: false });
        localStorage.setItem('welcomeModal', '2024');
    }

    closeUpdatedModal() {
        this.setState({ openUpdatedPopup: false });
        localStorage.setItem('updatedPlayerModal', true);
    }

    render() {
        const { t } = this.props;
        const { loading, headerMovie, movies, openPlayerPopup, openUpdatedPopup, openWarning, user } = this.state;

        const translation = getTranslation(headerMovie, i18next.language, Prefix, DefaultTranslation);

        if (loading) {
            return (
                <Body bodyClass="content headerContainer" title={`${t('NavBar.Movies')} | CinEd`}>
                    <Loading />
                </Body>
            );
        }

        return (
            <Body bodyClass="content headerContainer" title={`${t('NavBar.Movies')} | CinEd`}>
                <PageHeader
                    isMovie={true}
                    imgSrc={`${BASE_URL}${getMovieImageURL(headerMovie, 'FavoritePhotogram')}`}
                    title={headerMovie.OriginalTitle}
                    body={translation.Synopsis}
                    titleTranslation={translation.TitleTranslation}
                    link={`/movies/${headerMovie.id}`}
                    anchorLink="#movie-collection"
                />

                <div id="movie-collection" className="videoItemsList">
                    {movies.map((movie) => (
                        <VideoCard key={movie.id} movie={movie} isMovie={true} />
                    ))}
                </div>

                <div id="thematic-videos" ref={this.thematicDiv}>
                    <PedagogicalVideos />
                </div>

                <WelcomePopup open={openPlayerPopup} closeModal={() => this.closeWelcomeModal()} />

                <UpdatePopup open={openUpdatedPopup} closeModal={() => this.closeUpdatedModal()} />

                <AccessPopup
                    open={openWarning}
                    closeModal={() => this.setState({ openWarning: false })}
                    name={user?.name}
                    migrationToken={user?.migrationToken}
                />
            </Body>
        );
    }
}

export default withTranslation(['translation'])(CollectionClass);
